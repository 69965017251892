import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

// 각 기능별 컴포넌트 import
import CampaignFeature from './features/CampaignFeature';
import LeadFeature from './features/LeadFeature';
import CustomerFeature from './features/CustomerFeature';
// import OrderFeature from './features/OrderFeature';
// import SettlementFeature from './features/SettlementFeature';
// import ShippingFeature from './features/ShippingFeature';
// import PartnerFeature from './features/PartnerFeature';
// import EmployeeFeature from './features/EmployeeFeature';
// import InventoryFeature from './features/InventoryFeature';
// import FinancialFeature from './features/FinancialFeature';

const featureComponents = {
  'feature-campaign': CampaignFeature,
  'feature-lead': LeadFeature,
  'feature-customer': CustomerFeature,
//   'feature-order': OrderFeature,
//   'feature-settlement': SettlementFeature,
//   'feature-shipping': ShippingFeature,
//   'feature-partner': PartnerFeature,
//   'feature-employee': EmployeeFeature,
//   'feature-inventory': InventoryFeature,
//   'feature-financial': FinancialFeature,
};

const FeatureDetails = () => {
  const { t } = useTranslation();

  const featureDetails = [
    {
      id: 'feature-campaign',
      title: t('featureDetails.campaign.title'),
      subTitle: t('featureDetails.campaign.subTitle'),
      description: t('featureDetails.campaign.description'),
      features: [
        // ... 광고 관리 관련 features
      ]
    },
    {
      id: 'feature-lead',
      title: t('featureDetails.lead.title'),
      subTitle: t('featureDetails.lead.subTitle'),
      description: t('featureDetails.lead.description'),
      features: [
        // ... DB 관리 관련 features
      ]
    },
    {
      id: 'feature-customer',
      title: t('featureDetails.customer.title'),
      subTitle: t('featureDetails.customer.subTitle'),
      description: t('featureDetails.customer.description'),
      features: [
        // ... 고객 관리 관련 features
      ]
    },
    // ... 다른 기능들의 상세 정보
  ];

  return (
    <Box id="feature-details">
      {featureDetails.map((feature, index) => {
        const FeatureComponent = featureComponents[feature.id];
        return (
          <Box key={feature.id} id={feature.id}>
            <FeatureComponent 
              {...feature} 
              isReversed={index % 2 === 1}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default FeatureDetails;