import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import theme from './theme/theme';
import './i18n/i18n';
import FeatureDetails from './components/FeatureDetails';

// Components
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import Benefits from './components/Benefits';
import Footer from './components/Footer';

function App() {
  const { t, i18n } = useTranslation();

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet>
          <html lang={i18n.language} />
          <title>{t('meta.title')}</title>
          <meta 
            name="description" 
            content={t('meta.description')}
          />
          <link 
            rel="stylesheet" 
            href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css" 
          />
        </Helmet>

        <Header />
        <main>
          <HeroSection />
          <Features />
          <FeatureDetails />
          <Benefits />
        </main>
        <Footer />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;