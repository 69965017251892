import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Box, 
  Container, 
  Grid, 
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { 
  People,
  GroupAdd,
  Loyalty,
  TrendingUp,
  Stars,
  AttachMoney,
  PersonOff
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const CustomerDashboard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const periods = ['daily', 'weekly', 'monthly', 'yearly'];
  const metrics = [
    { 
      icon: <People />, 
      key: 'totalCustomers',
      value: t('customerFeature.dashboard.metrics.totalCustomers.value'),
      trend: t('customerFeature.dashboard.metrics.totalCustomers.trend')
    },
    { 
      icon: <GroupAdd />, 
      key: 'newCustomers',
      value: t('customerFeature.dashboard.metrics.newCustomers.value'),
      trend: t('customerFeature.dashboard.metrics.newCustomers.trend')
    },
    { 
      icon: <Stars />, 
      key: 'vipCustomers',
      value: t('customerFeature.dashboard.metrics.vipCustomers.value'),
      trend: t('customerFeature.dashboard.metrics.vipCustomers.trend')
    },
    { 
      icon: <PersonOff />, 
      key: 'dormantCustomers',
      value: t('customerFeature.dashboard.metrics.dormantCustomers.value'),
      trend: t('customerFeature.dashboard.metrics.dormantCustomers.trend')
    }
  ];

  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #fff 0%, #f8f9fa 100%)',
        borderRadius: 4,
        p: { xs: 2, sm: 3, md: 4 },
        minHeight: { xs: '400px', sm: '500px', md: '600px' },
        border: '1px solid rgba(0,0,0,0.1)',
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)'
        },
        gridTemplateRows: {
          xs: 'auto auto auto auto auto',
          sm: 'auto auto auto auto',
          md: 'auto auto 1fr auto'
        },
        gap: { xs: 2, sm: 2, md: 3 },
        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
        opacity: 0.9,
        cursor: 'default',
        filter: 'grayscale(10%)'
      }}
    >
      {/* 헤더 섹션 */}
      <Box sx={{ 
        gridColumn: '1 / -1', 
        mb: { xs: 1, sm: 2 }
      }}>
        <Typography 
          variant={isMobile ? "h6" : "h5"} 
          gutterBottom 
          sx={{ fontWeight: 600 }}
        >
          {t('customerFeature.dashboard.title')}
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          gap: { xs: 1, sm: 2 },
          flexWrap: 'wrap'
        }}>
          {periods.map((period) => (
            <Box
              key={period}
              sx={{
                px: { xs: 2, sm: 3 },
                py: { xs: 0.5, sm: 1 },
                borderRadius: 2,
                bgcolor: period === 'monthly' ? 'primary.main' : 'transparent',
                color: period === 'monthly' ? 'white' : 'text.secondary',
                fontSize: { xs: '0.875rem', sm: '1rem' }
              }}
            >
              {t(`customerFeature.dashboard.periods.${period}`)}
            </Box>
          ))}
        </Box>
      </Box>

      {/* 주요 지표 카드 */}
      {metrics.map((metric, index) => (
        <Box
          key={index}
          sx={{
            bgcolor: 'white',
            p: { xs: 2, sm: 3 },
            borderRadius: 2,
            boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 1, sm: 2 }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
            {React.cloneElement(metric.icon, { 
              sx: { 
                color: 'primary.main', 
                fontSize: { xs: 24, sm: 28 } 
              } 
            })}
            <Typography 
              variant={isMobile ? "subtitle1" : "h6"} 
              color="text.secondary"
            >
              {t(`customerFeature.dashboard.metrics.${metric.key}.title`)}
            </Typography>
          </Box>
          <Typography 
            variant={isMobile ? "h5" : "h4"} 
            sx={{ fontWeight: 'bold' }}
          >
            {metric.value}
          </Typography>
          <Typography 
            variant={isMobile ? "body2" : "subtitle1"}
            sx={{ 
              color: metric.trend.includes('+') ? 'success.main' : 'error.main',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontWeight: 500
            }}
          >
            {metric.trend}
          </Typography>
        </Box>
      ))}

      {/* 고객 활동 추이 그래프 */}
      <Box
        sx={{
          gridColumn: '1 / -1',
          bgcolor: 'white',
          borderRadius: 2,
          p: { xs: 2, sm: 3 },
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          minHeight: { xs: '200px', sm: '250px', md: '300px' }
        }}
      >
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          {t('customerFeature.dashboard.graph.title')}
        </Typography>
        <Box
          sx={{
            flex: 1,
            background: 'linear-gradient(180deg, rgba(33,150,243,0.1) 0%, rgba(33,150,243,0) 100%)',
            borderRadius: 1,
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '80%',
              background: `linear-gradient(180deg, 
                transparent 0%, 
                rgba(33,150,243,0.2) 50%, 
                transparent 100%)`,
              clipPath: 'polygon(0 80%, 20% 95%, 40% 75%, 60% 85%, 80% 65%, 100% 70%, 100% 100%, 0 100%)'
            }}
          />
        </Box>
      </Box>

      {/* 고객 등급별 분포 */}
      <Box
        sx={{
          gridColumn: '1 / -1',
          bgcolor: 'white',
          borderRadius: 2,
          p: { xs: 2, sm: 3 },
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
        }}
      >
        <Typography 
          variant={isMobile ? "subtitle1" : "h6"} 
          gutterBottom
        >
          {t('customerFeature.dashboard.distribution.title')}
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 2, sm: 4 }, 
          mt: 2 
        }}>
          {t('customerFeature.dashboard.distribution.grades', { returnObjects: true }).map((grade) => (
            <Box
              key={grade}
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5
              }}
            >
              <Typography 
                variant={isMobile ? "body2" : "subtitle1"} 
                sx={{ fontWeight: 500 }}
              >
                {grade}
              </Typography>
              <Box
                sx={{
                  height: { xs: '6px', sm: '8px' },
                  bgcolor: 'grey.100',
                  borderRadius: 1,
                  overflow: 'hidden'
                }}
              >
                <Box
                  sx={{
                    width: grade === 'VIP' ? '15%' : 
                          grade === 'Gold' ? '25%' : 
                          grade === 'Silver' ? '35%' : '25%',
                    height: '100%',
                    bgcolor: 'primary.main'
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const CustomerFeature = ({ title, subTitle, description, isReversed }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box 
      sx={{ 
        py: { xs: 8, md: 12 },
        bgcolor: isReversed ? 'grey.50' : 'white'
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ mb: 6 }}>
          <Typography 
            variant="h3" 
            gutterBottom 
            sx={{ 
              fontWeight: 700,
              mb: 2
            }}
          >
            {title}
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              color: 'primary.main',
              mb: 3,
              fontWeight: 500
            }}
          >
            {subTitle}
          </Typography>
          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ 
              lineHeight: 1.8,
              maxWidth: '800px'
            }}
          >
            {description}
          </Typography>
        </Box>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          style={{ 
            userSelect: 'none',
            pointerEvents: 'none'
          }}
        >
          <Box sx={{ 
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(1px)',
              borderRadius: 4,
              zIndex: 1
            }
          }}>
            <CustomerDashboard />
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default CustomerFeature;