import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Box, 
  Container, 
  Grid, 
  Typography, 
  IconButton, 
  Link 
} from '@mui/material';
import { 
  Facebook, 
  Twitter, 
  Instagram, 
  LinkedIn 
} from '@mui/icons-material';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ bgcolor: 'grey.900', color: 'white', py: 6 }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {t('footer.company.name')}
            </Typography>
            <Typography variant="body2" color="grey.400">
              {t('footer.company.slogan')}
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {t('footer.contact.title')}
            </Typography>
            <Typography variant="body2" color="grey.400">
              {t('footer.contact.email')}<br />
              {t('footer.contact.tel')}<br />
              {t('footer.contact.address')}
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {t('footer.social.title')}
            </Typography>
            <Box>
              {/* <IconButton 
                color="inherit" 
                aria-label={t('footer.social.facebook')}
              >
                <Facebook />
              </IconButton>
              <IconButton 
                color="inherit"
                aria-label={t('footer.social.twitter')}
              >
                <Twitter />
              </IconButton>
              <IconButton 
                color="inherit"
                aria-label={t('footer.social.instagram')}
              >
                <Instagram />
              </IconButton>
              <IconButton 
                color="inherit"
                aria-label={t('footer.social.linkedin')}
              >
                <LinkedIn />
              </IconButton> */}
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, pt: 2, borderTop: '1px solid rgba(255,255,255,0.1)' }}>
          <Typography variant="body2" color="grey.400" align="center">
            {t('footer.copyright')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;