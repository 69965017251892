import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  Box
} from '@mui/material';
import { Menu as MenuIcon, Language } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [scrolled, setScrolled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { name: t('nav.features'), target: 'features' },
    { name: t('nav.benefits'), target: 'benefits' },
  ];

  // 모바일 메뉴 컴포넌트
  const drawer = (
    <Box sx={{ width: 250, pt: 2 }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.target} sx={{ px: 2 }}>
            <ScrollLink
              to={item.target}
              smooth={true}
              duration={500}
              offset={-64}
              onClick={handleDrawerToggle}
              style={{ width: '100%' }}
            >
              <Button 
                fullWidth
                sx={{ 
                  justifyContent: 'flex-start',
                  color: 'text.primary'
                }}
              >
                {item.name}
              </Button>
            </ScrollLink>
          </ListItem>
        ))}
        <ListItem sx={{ px: 2 }}>
          <Button 
            fullWidth
            onClick={() => handleLanguageChange('ko')}
            sx={{ justifyContent: 'flex-start' }}
          >
            한국어
          </Button>
        </ListItem>
        <ListItem sx={{ px: 2 }}>
          <Button 
            fullWidth
            onClick={() => handleLanguageChange('en')}
            sx={{ justifyContent: 'flex-start' }}
          >
            English
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar 
      position="fixed" 
      color={scrolled ? 'default' : 'transparent'}
      elevation={scrolled ? 4 : 0}
      sx={{
        transition: 'all 0.3s',
        backdropFilter: scrolled ? 'blur(10px)' : 'none',
        backgroundColor: scrolled ? 'rgba(255, 255, 255, 0.9)' : 'transparent'
      }}
    >
      <Container>
        <Toolbar>
          <ScrollLink
              to="home"
              smooth={true}
              duration={800}
              offset={0}
              style={{ 
                flexGrow: 1,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none'                
              }}
            >
              <Box
                component="img"
                src="/logo.png"
                alt="ORDARS"
                sx={{
                  height: 32,
                  width: 'auto',
                  mr: 1,
                  transition: 'opacity 0.3s',
                  filter: scrolled ? 'none' : 'brightness(0) invert(1)', // 스크롤 전에는 흰색으로
                  '&:hover': {
                    opacity: 0.8
                  }
                }}
              />              
              <Typography 
                variant="h6" 
                sx={{ 
                  color: scrolled ? 'text.primary' : 'white',
                  fontFamily: 'Montserrat, Pretendard, sans-serif', // 고급스러운 폰트 적용
                  fontWeight: 600, // semi-bold
                  letterSpacing: '0.05em', // 자간 조정
                  textTransform: 'uppercase', // 대문자로 변환
                  '&:hover': {
                    opacity: 0.8
                  }
                }}
              >
                ORDARS
              </Typography>
            </ScrollLink>
          
          {isMobile ? (
            <IconButton 
              color="inherit"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <>
              {navItems.map((item) => (
                <ScrollLink
                  key={item.target}
                  to={item.target}
                  smooth={true}
                  duration={500}
                  offset={-64}
                >
                  <Button 
                    color="inherit"
                    sx={{ 
                      mx: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)'
                      }
                    }}
                  >
                    {item.name}
                  </Button>
                </ScrollLink>
              ))}
              <IconButton 
                color="inherit"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <Language />
              </IconButton>
            </>
          )}

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleLanguageChange('ko')}>한국어</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
          </Menu>

          {/* 모바일 메뉴 Drawer */}
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { 
                boxSizing: 'border-box', 
                width: 250,
                backgroundColor: 'background.paper'
              },
            }}
          >
            {drawer}
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;