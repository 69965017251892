import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Box, 
  Container, 
  Grid, 
  Typography,
  Card,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { 
  Campaign,
  Timeline, 
  Assessment,
  MonetizationOn,
  TrendingUp
} from '@mui/icons-material';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';

const CampaignDashboard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const periods = ['daily', 'weekly', 'monthly', 'yearly'];
  const metrics = [
    { 
      icon: <Campaign />, 
      key: 'adCost',
      value: t('campaignFeature.dashboard.metrics.adCost.value'),
      trend: t('campaignFeature.dashboard.metrics.adCost.trend')
    },
    { 
      icon: <MonetizationOn />, 
      key: 'revenue',
      value: t('campaignFeature.dashboard.metrics.revenue.value'),
      trend: t('campaignFeature.dashboard.metrics.revenue.trend')
    },
    { 
      icon: <Assessment />, 
      key: 'conversionRate',
      value: t('campaignFeature.dashboard.metrics.conversionRate.value'),
      trend: t('campaignFeature.dashboard.metrics.conversionRate.trend')
    },
    { 
      icon: <TrendingUp />, 
      key: 'roas',
      value: t('campaignFeature.dashboard.metrics.roas.value'),
      trend: t('campaignFeature.dashboard.metrics.roas.trend')
    }
  ];

  const chartData = [
    { date: '1일', impression: 2400, click: 1800, conversion: 800 },
    { date: '2일', impression: 1398, click: 1000, conversion: 600 },
    { date: '3일', impression: 9800, click: 2908, conversion: 1200 },
    { date: '4일', impression: 3908, click: 2800, conversion: 1500 },
    { date: '5일', impression: 4800, click: 3200, conversion: 1700 },
    { date: '6일', impression: 3800, click: 2800, conversion: 1400 },
    { date: '7일', impression: 4300, click: 3100, conversion: 1600 },
  ];

  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #fff 0%, #f8f9fa 100%)',
        borderRadius: 4,
        p: { xs: 2, sm: 3, md: 4 },
        minHeight: { xs: '400px', sm: '500px', md: '600px' },
        border: '1px solid rgba(0,0,0,0.1)',
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)'
        },
        gridTemplateRows: {
          xs: 'auto auto auto auto auto',
          sm: 'auto auto auto auto',
          md: 'auto auto 1fr auto'
        },
        gap: { xs: 2, sm: 2, md: 3 },
        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
        opacity: 0.9,
        cursor: 'default',
        filter: 'grayscale(10%)'
      }}
    >
      {/* Header Section */}
      <Box sx={{ 
        gridColumn: '1 / -1', 
        mb: { xs: 1, sm: 2 }
      }}>
        <Typography 
          variant={isMobile ? "h6" : "h5"} 
          gutterBottom 
          sx={{ fontWeight: 600 }}
        >
          {t('campaignFeature.dashboard.title')}
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          gap: { xs: 1, sm: 2 },
          flexWrap: 'wrap'
        }}>
          {periods.map((period) => (
            <Box
              key={period}
              sx={{
                px: { xs: 2, sm: 3 },
                py: { xs: 0.5, sm: 1 },
                borderRadius: 2,
                bgcolor: period === 'daily' ? 'primary.main' : 'transparent',
                color: period === 'daily' ? 'white' : 'text.secondary',
                fontSize: { xs: '0.875rem', sm: '1rem' }
              }}
            >
              {t(`campaignFeature.dashboard.periods.${period}`)}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Metrics Cards */}
      {metrics.map((metric, index) => (
        <Box
          key={index}
          sx={{
            bgcolor: 'white',
            p: { xs: 2, sm: 3 },
            borderRadius: 2,
            boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 1, sm: 2 }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
            {React.cloneElement(metric.icon, { 
              sx: { 
                color: 'primary.main', 
                fontSize: { xs: 24, sm: 28 } 
              } 
            })}
            <Typography 
              variant={isMobile ? "subtitle1" : "h6"} 
              color="text.secondary"
            >
              {t(`campaignFeature.dashboard.metrics.${metric.key}.title`)}
            </Typography>
          </Box>
          <Typography 
            variant={isMobile ? "h5" : "h4"} 
            sx={{ fontWeight: 'bold' }}
          >
            {metric.value}
          </Typography>
          <Typography 
            variant={isMobile ? "body2" : "subtitle1"}
            sx={{ 
              color: metric.trend.startsWith('+') ? 'success.main' : 'error.main',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontWeight: 500
            }}
          >
            {metric.trend}
          </Typography>
        </Box>
      ))}

      {/* Graph Section */}
      <Box
        sx={{
          gridColumn: '1 / -1',
          bgcolor: 'white',
          borderRadius: 2,
          p: { xs: 2, sm: 3 },
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          minHeight: { xs: '200px', sm: '250px', md: '300px' }
        }}
      >
        <Typography variant={isMobile ? "subtitle1" : "h6"}>
          {t('campaignFeature.dashboard.graph.title')}
        </Typography>
        <Box sx={{ flex: 1, width: '100%', minHeight: '200px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
              <XAxis 
                dataKey="date" 
                stroke="#666"
                fontSize={12}
              />
              <YAxis 
                stroke="#666"
                fontSize={12}
              />
              <Tooltip 
                contentStyle={{
                  backgroundColor: 'white',
                  border: '1px solid #f0f0f0',
                  borderRadius: '4px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
              />
              <Legend 
                verticalAlign="top" 
                height={36}
                iconType="circle"
              />
              <Line
                type="monotone"
                dataKey="impression"
                name={t('campaignFeature.dashboard.graph.metrics.impressions')}
                stroke="#8884d8"
                strokeWidth={2}
                dot={{ r: 3 }}
                activeDot={{ r: 5 }}
              />
              <Line
                type="monotone"
                dataKey="click"
                name={t('campaignFeature.dashboard.graph.metrics.clicks')}
                stroke="#82ca9d"
                strokeWidth={2}
                dot={{ r: 3 }}
                activeDot={{ r: 5 }}
              />
              <Line
                type="monotone"
                dataKey="conversion"
                name={t('campaignFeature.dashboard.graph.metrics.conversions')}
                stroke="#ffc658"
                strokeWidth={2}
                dot={{ r: 3 }}
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      {/* Platform Performance */}
      <Box
        sx={{
          gridColumn: '1 / -1',
          bgcolor: 'white',
          borderRadius: 2,
          p: { xs: 2, sm: 3 },
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
        }}
      >
        <Typography 
          variant={isMobile ? "subtitle1" : "h6"} 
          gutterBottom
        >
          {t('campaignFeature.dashboard.platforms.title')}
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 2, sm: 4 }, 
          mt: 2 
        }}>
          {t('campaignFeature.dashboard.platforms.names', { returnObjects: true }).map((platform) => (
            <Box
              key={platform}
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5
              }}
            >
              <Typography 
                variant={isMobile ? "body2" : "subtitle1"} 
                sx={{ fontWeight: 500 }}
              >
                {platform}
              </Typography>
              <Box
                sx={{
                  height: { xs: '6px', sm: '8px' },
                  bgcolor: 'grey.100',
                  borderRadius: 1,
                  overflow: 'hidden'
                }}
              >
                <Box
                  sx={{
                    width: `${Math.random() * 60 + 40}%`,
                    height: '100%',
                    bgcolor: 'primary.main'
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const CampaignFeature = ({ title, subTitle, description, isReversed }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box 
      sx={{ 
        py: { xs: 8, md: 12 },
        bgcolor: isReversed ? 'grey.50' : 'white'
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ mb: 6 }}>
          <Typography 
            variant="h3" 
            gutterBottom 
            sx={{ 
              fontWeight: 700,
              mb: 2
            }}
          >
            {title}
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              color: 'primary.main',
              mb: 3,
              fontWeight: 500
            }}
          >
            {subTitle}
          </Typography>
          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ 
              lineHeight: 1.8,
              maxWidth: '800px'
            }}
          >
            {description}
          </Typography>
        </Box>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          style={{ 
            userSelect: 'none',
            pointerEvents: 'none'
          }}
        >
          <Box sx={{ 
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(1px)',
              borderRadius: 4,
              zIndex: 1
            }
          }}>
            <CampaignDashboard />
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default CampaignFeature;