import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Box, 
  Container, 
  Grid, 
  Typography, 
  Card, 
  CardContent,
  CardActionArea,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { 
  Campaign,           // Ad Management
  ContactPage,        // Lead Management
  People,            // Customer Management
  ShoppingCart,      // Order Management
  Payments,          // Settlement Management
  LocalShipping,     // Shipping Management
  BusinessCenter,    // Partner Management
  Badge,             // Employee Management
  Inventory,         // Inventory Management
  AccountBalance     // Financial Management
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';

const FeatureCard = ({ icon, title, subTitle, description, index, target }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ScrollLink 
      to={target} 
      smooth={true} 
      duration={800} 
      offset={-64}
      style={{ textDecoration: 'none' }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        viewport={{ once: true }}
      >
        <Card 
          sx={{ 
            height: '100%',
            background: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(10px)',
            transition: 'all 0.3s ease',
            border: '1px solid rgba(255,255,255,0.3)',
            '&:hover': {
              transform: 'translateY(-8px)',
              boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
              background: 'rgba(255, 255, 255, 0.95)',
              '& .feature-icon': {
                transform: 'scale(1.1)',
                color: theme.palette.primary.main
              }
            }
          }}
        >
          <CardActionArea 
            sx={{ 
              height: '100%', 
              p: isMobile ? 2 : 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <CardContent sx={{ width: '100%' }}>
              <Box 
                className="feature-icon"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  mb: 2,
                  transition: 'all 0.3s ease',
                  color: theme.palette.text.primary
                }}
              >
                {React.cloneElement(icon, { 
                  sx: { fontSize: isMobile ? 28 : 32 } 
                })}
                <Box sx={{ ml: 1.5 }}>
                  <Typography 
                    variant={isMobile ? "subtitle1" : "h6"} 
                    sx={{ fontWeight: 600 }}
                  >
                    {title}
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: 'primary.main',
                      opacity: 0.8,
                      fontWeight: 500
                    }}
                  >
                    {subTitle}
                  </Typography>
                </Box>
              </Box>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  lineHeight: 1.6,
                  opacity: 0.8
                }}
              >
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </motion.div>
    </ScrollLink>
  );
};

const Features = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: <Campaign />,
      title: t('features.items.campaign.title'),
      subTitle: t('features.items.campaign.subTitle'),
      description: t('features.items.campaign.description'),
      target: "feature-campaign"
    },
    {
      icon: <ContactPage />,
      title: t('features.items.lead.title'),
      subTitle: t('features.items.lead.subTitle'),
      description: t('features.items.lead.description'),
      target: "feature-lead"
    },
    {
      icon: <People />,
      title: t('features.items.customer.title'),
      subTitle: t('features.items.customer.subTitle'),
      description: t('features.items.customer.description'),
      target: "feature-customer"
    },
    {
      icon: <ShoppingCart />,
      title: t('features.items.order.title'),
      subTitle: t('features.items.order.subTitle'),
      description: t('features.items.order.description'),
      target: "feature-order"
    },
    {
      icon: <AccountBalance />,
      title: t('features.items.financial.title'),
      subTitle: t('features.items.financial.subTitle'),
      description: t('features.items.financial.description'),
      target: "feature-financial"
    },
    {
      icon: <Badge />,
      title: t('features.items.employee.title'),
      subTitle: t('features.items.employee.subTitle'),
      description: t('features.items.employee.description'),
      target: "feature-employee"
    },            
    {
      icon: <BusinessCenter />,
      title: t('features.items.partner.title'),
      subTitle: t('features.items.partner.subTitle'),
      description: t('features.items.partner.description'),
      target: "feature-partner"
    },
    {
      icon: <Inventory />,
      title: t('features.items.inventory.title'),
      subTitle: t('features.items.inventory.subTitle'),
      description: t('features.items.inventory.description'),
      target: "feature-inventory"
    },
    {
      icon: <Payments />,
      title: t('features.items.settlement.title'),
      subTitle: t('features.items.settlement.subTitle'),
      description: t('features.items.settlement.description'),
      target: "feature-settlement"
    },
    {
      icon: <LocalShipping />,
      title: t('features.items.shipping.title'),
      subTitle: t('features.items.shipping.subTitle'),
      description: t('features.items.shipping.description'),
      target: "feature-shipping"
    }    
  ];

  return (
    <Box 
      id="features"
      sx={{ 
        py: { xs: 6, md: 10 },
        background: 'linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%)',
        scrollMarginTop: '64px'
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <Typography 
            variant="h3" 
            component="h2" 
            textAlign="center" 
            mb={2}
            sx={{ 
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            {t('features.title')}
          </Typography>
          <Typography 
            variant="h6" 
            textAlign="center" 
            color="text.secondary"
            mb={6}
            sx={{ 
              maxWidth: '800px', 
              mx: 'auto',
              px: 2,
              opacity: 0.8
            }}
          >
            {t('features.subtitle')}
          </Typography>
        </motion.div>

        <Grid container spacing={3}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FeatureCard {...feature} index={index} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;