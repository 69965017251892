import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      meta: {
        title: "ORDARS.COM - Introduction",
        description: "A comprehensive management platform that makes it easy to manage customers, assets, and business partners."
      },      
      nav: {
        features: "Features",
        benefits: "Benefits",
        contact: "Contact"
      },
      hero: {
        title: "Welcome to ordars.com!",
        subtitle: "A comprehensive management platform for your business growth",
        description: "ordars.com is a comprehensive management platform that makes it easy to manage customers, assets, and partners.",
        cta: {
          start: "Get Started",
          learn: "Learn More"
        }
      },
      features: {
        title: "Main Features",
        subtitle: "We provide essential features for business growth",
        items: {
          campaign: {
            title: "Ad Management",
            subTitle: "Campaign Management",
            description: "Efficiently manage ads across major SNS platforms like Facebook, Instagram, Google, and TikTok."
          },
          lead: {
            title: "DB Management",
            subTitle: "Lead Management",
            description: "Systematically manage consultation request data obtained through advertisements."
          },
          customer: {
            title: "Customer Management",
            subTitle: "Customer Management",
            description: "Provide customized services based on customer information to achieve continuous results."
          },
          order: {
            title: "Performance Management",
            subTitle: "Order Management",
            description: "Track business growth by managing product sales performance and revenue."
          },
          settlement: {
            title: "Settlement Management",
            subTitle: "Settlement Management",
            description: "Automate and efficiently manage performance-based settlements."
          },
          shipping: {
            title: "Shipping Management",
            subTitle: "Shipping Management",
            description: "Track and manage product sales and shipping status in real-time."
          },
          partner: {
            title: "Partner Management",
            subTitle: "Partner Management",
            description: "Manage relationships with partners horizontally for mutual benefit."
          },
          employee: {
            title: "Employee Management",
            subTitle: "Employee Management",
            description: "Efficiently manage information and payroll for full-time employees and freelancers."
          },
          inventory: {
            title: "Inventory Management",
            subTitle: "Inventory Management",
            description: "Track and manage detailed inventory in and out records."
          },
          financial: {
            title: "Asset Management",
            subTitle: "Financial Management",
            description: "Integrated management of financial situations including bank accounts, cards, and tax invoices."
          }
        }
      },
      featureDetails: {
        campaign: {
          title: "Ad Management",
          subTitle: "Campaign Management",
          description: "Efficiently manage various advertising platforms in a single dashboard. Optimize your advertising performance with real-time data analysis and automated reporting."
        },
        lead: {
          title: "DB Management",
          subTitle: "Lead Management",
          description: "Systematically manage consultation request data obtained through advertisements."
        },
        customer: {
          title: "Customer Management",
          subTitle: "Customer Management",
          description: "Acquire customers through DB management and achieve continuous results by providing customized services based on customer information."
        }
      },
      campaignFeature: {
        dashboard: {
          title: "Ad Campaign Dashboard",
          periods: {
            daily: "Daily",
            weekly: "Weekly",
            monthly: "Monthly",
            yearly: "Yearly"
          },
          metrics: {
            adCost: {
              title: "Total Ad Cost",
              value: "₩1,234,567",
              trend: "+12.3%"
            },
            revenue: {
              title: "Revenue",
              value: "₩8,765,432",
              trend: "+15.7%"
            },
            conversionRate: {
              title: "Conversion Rate",
              value: "3.45%",
              trend: "+0.5%"
            },
            roas: {
              title: "ROAS",
              value: "245%",
              trend: "+22.3%"
            }
          },
          graph: {
            title: "Ad Performance Trend",
            metrics: {
              impressions: "Impressions",
              clicks: "Clicks",
              conversions: "Conversions"
            }
          },
          platforms: {
            title: "Platform Performance",
            names: ["Meta", "Google", "Naver", "Kakao"]
          }
        }
      },       
      leadFeature: {
        dashboard: {
          title: "Consultation DB Dashboard",
          periods: {
            today: "Today",
            thisWeek: "This Week",
            thisMonth: "This Month",
            all: "All"
          },
          metrics: {
            totalConsultations: {
              title: "Total Consultations",
              value: "1,234",
              trend: "+23 cases"
            },
            conversionRate: {
              title: "Conversion Rate",
              value: "38.5%",
              trend: "+2.4%"
            },
            contracts: {
              title: "Contracts",
              value: "475",
              trend: "+15 cases"
            },
            pending: {
              title: "Pending Cases",
              value: "28",
              trend: "-3 cases"
            }
          },
          graph: {
            title: "Consultation Inflow Trend"
          },
          distribution: {
            title: "Consultation Type Distribution",
            types: [
              "Phone Consultation",
              "Online Consultation",
              "Visit Consultation",
              "Others"
            ]
          }
        }
      },      
      customerFeature: {
        dashboard: {
          title: "Customer Status Dashboard",
          periods: {
            daily: "Daily",
            weekly: "Weekly",
            monthly: "Monthly",
            yearly: "Yearly"
          },
          metrics: {
            totalCustomers: {
              title: "Total Customers",
              value: "12,345",
              trend: "+123 people"
            },
            newCustomers: {
              title: "New Customers",
              value: "483",
              trend: "+28 people"
            },
            vipCustomers: {
              title: "VIP Customers",
              value: "237",
              trend: "+12 people"
            },
            dormantCustomers: {
              title: "Dormant Customers",
              value: "891",
              trend: "-15 people"
            }
          },
          graph: {
            title: "Customer Activity Trend"
          },
          distribution: {
            title: "Customer Grade Distribution",
            grades: ["VIP", "Gold", "Silver", "Bronze"]
          }
        }
      },      
      benefits: {
        title: "Why Choose ordars.com?",
        subtitle: "We provide the best solutions for business growth",
        items: [
          {
            title: "All-in-One Solution",
            description: "Solve all business management functions on one platform."
          },
          {
            title: "Intuitive Interface",
            description: "Provides an easy user experience that anyone can use."
          },
          {
            title: "Efficient Business Management",
            description: "Accelerate business growth with data-driven decision making."
          },
          {
            title: "Cross-Platform Support",
            description: "Provides the same features and experience on PC and mobile apps, allowing work anytime, anywhere."
          },
          {
            title: "Integrated Management System",
            description: "Manage core business elements perfectly with specialized features for DB management, customer management, performance management, and partner management."
          }
        ]
      },
      footer: {
        company: {
          name: "ORDARS",
          slogan: "The Best Partner for Business Growth"
        },
        contact: {
          title: "Contact",
          email: "Email: ordars.com@gmail.com",
          tel: "Tel: 02-1522-5512",
          address: "Address: #2114, Building B, 236 Jubuto-ro, Bupyeong-gu, Incheon"
        },
        social: {
          title: "Follow Us",
          facebook: "Facebook",
          twitter: "Twitter",
          instagram: "Instagram",
          linkedin: "LinkedIn"
        },
        copyright: "© 2024 ordars.com. All rights reserved."
      }
    }
  },
  ko: {
    translation: {
      meta: {
        title: "오다스닷컴 - 소개",
        description: "고객관리, 자산관리, 거래처관리를 손쉽게 할 수 있는 종합 관리 플랫폼입니다."
      },      
      nav: {
        features: "주요기능",
        benefits: "특장점",
        contact: "문의하기"
      },
      hero: {
        title: "방문을 환영합니다!!",
        subtitle: "비즈니스 성장을 위한 종합 관리 플랫폼",
        description: "오다스닷컴은 고객관리, 자산관리, 거래처 관리를 손쉽게 할 수 있는 종합 관리 플랫폼입니다.",
        cta: {
          start: "시작하기",
          learn: "더 알아보기"
        }
      },
      features: {
        title: "주요 기능",
        subtitle: "비즈니스 성장을 위한 필수 기능을 제공합니다",
        items: {
          campaign: {
            title: "광고 관리",
            subTitle: "Campaign Management",
            description: "페이스북, 인스타그램, 구글, 틱톡 등 주요 SNS 플랫폼의 광고를 효율적으로 관리하세요."
          },
          lead: {
            title: "DB 관리",
            subTitle: "Lead Management",
            description: "광고를 통해 유입된 상담 신청 데이터를 체계적으로 관리할 수 있습니다."
          },
          customer: {
            title: "고객 관리",
            subTitle: "Customer Management",
            description: "고객 정보를 바탕으로 맞춤형 서비스를 제공해 지속적인 성과를 얻을 수 있습니다."
          },
          order: {
            title: "실적 관리",
            subTitle: "Order Management",
            description: "상품 판매 실적과 매출을 관리하여 비즈니스 성장을 추적할 수 있습니다."
          },
          settlement: {
            title: "정산 관리",
            subTitle: "Settlement Management",
            description: "성과에 대한 정산을 자동화하고 효율적으로 관리할 수 있습니다."
          },
          shipping: {
            title: "배송 관리",
            subTitle: "Shipping Management",
            description: "상품 판매와 배송 상황을 실시간으로 추적하고 관리할 수 있습니다."
          },
          partner: {
            title: "업체 관리",
            subTitle: "Partner Management",
            description: "협력업체와의 관계를 수평적으로 관리하여 상호 이익을 도모합니다."
          },
          employee: {
            title: "직원 관리",
            subTitle: "Employee Management",
            description: "정규직과 프리랜서의 정보와 급여를 효율적으로 관리합니다."
          },
          inventory: {
            title: "재고 관리",
            subTitle: "Inventory Management",
            description: "재고의 입출고 내역을 상세하게 추적하고 관리할 수 있습니다."
          },
          financial: {
            title: "자산 관리",
            subTitle: "Financial Management",
            description: "은행 계좌, 카드, 세금계산서 등 재무 상황을 통합 관리합니다."
          }
        }
      },
      featureDetails: {
        campaign: {
          title: "광고 관리",
          subTitle: "Campaign Management",
          description: "다양한 광고 플랫폼을 하나의 대시보드에서 효율적으로 관리하세요. 실시간 데이터 분석과 자동화된 리포팅으로 광고 성과를 최적화할 수 있습니다."
        },
        lead: {
          title: "DB 관리",
          subTitle: "Lead Management",
          description: "광고를 통해 유입된 상담 신청 데이터를 체계적으로 관리할 수 있습니다."
        },
        customer: {
          title: "고객 관리",
          subTitle: "Customer Management",
          description: "DB 관리를 통해 고객을 획득하고, 고객 정보를 바탕으로 맞춤형 서비스를 제공해 지속적인 성과를 얻을 수 있습니다."
        }
      },
      campaignFeature: {
        dashboard: {
          title: "광고 캠페인 대시보드",
          periods: {
            daily: "일간",
            weekly: "주간",
            monthly: "월간",
            yearly: "연간"
          },
          metrics: {
            adCost: {
              title: "총 광고비",
              value: "₩1,234,567",
              trend: "+12.3%"
            },
            revenue: {
              title: "매출액",
              value: "₩8,765,432",
              trend: "+15.7%"
            },
            conversionRate: {
              title: "전환율",
              value: "3.45%",
              trend: "+0.5%"
            },
            roas: {
              title: "ROAS",
              value: "245%",
              trend: "+22.3%"
            }
          },
          graph: {
            title: "광고 성과 추이",
            metrics: {
              impressions: "노출수",
              clicks: "클릭수",
              conversions: "전환수"
            }
          },
          platforms: {
            title: "플랫폼별 성과",
            names: ["Meta", "Google", "Naver", "Kakao"]
          }
        }
      },      
      leadFeature: {
        dashboard: {
          title: "상담 DB 대시보드",
          periods: {
            today: "오늘",
            thisWeek: "이번주",
            thisMonth: "이번달",
            all: "전체"
          },
          metrics: {
            totalConsultations: {
              title: "총 상담수",
              value: "1,234",
              trend: "+23건"
            },
            conversionRate: {
              title: "전환율",
              value: "38.5%",
              trend: "+2.4%"
            },
            contracts: {
              title: "계약 건수",
              value: "475",
              trend: "+15건"
            },
            pending: {
              title: "미처리 건수",
              value: "28",
              trend: "-3건"
            }
          },
          graph: {
            title: "상담 유입 추이"
          },
          distribution: {
            title: "상담 유형별 분포",
            types: [
              "전화상담",
              "온라인상담",
              "방문상담",
              "기타"
            ]
          }
        }
      },      
      customerFeature: {
        dashboard: {
          title: "고객 현황 대시보드",
          periods: {
            daily: "일간",
            weekly: "주간",
            monthly: "월간",
            yearly: "연간"
          },
          metrics: {
            totalCustomers: {
              title: "총 고객수",
              value: "12,345",
              trend: "+123명"
            },
            newCustomers: {
              title: "신규 고객",
              value: "483",
              trend: "+28명"
            },
            vipCustomers: {
              title: "VIP 고객",
              value: "237",
              trend: "+12명"
            },
            dormantCustomers: {
              title: "휴면 고객",
              value: "891",
              trend: "-15명"
            }
          },
          graph: {
            title: "고객 활동 추이"
          },
          distribution: {
            title: "고객 등급별 분포",
            grades: ["VIP", "Gold", "Silver", "Bronze"]
          }
        }
      },      
      benefits: {
        title: "왜 오다스닷컴인가?",
        subtitle: "비즈니스 성장을 위한 최적의 솔루션을 제공합니다",
        items: [
          {
            title: "올인원 솔루션",
            description: "모든 비즈니스 관리 기능을 하나의 플랫폼에서 해결하세요."
          },
          {
            title: "직관적인 인터페이스",
            description: "누구나 쉽게 사용할 수 있는 간편한 사용자 경험을 제공합니다."
          },
          {
            title: "효율적인 비즈니스 관리",
            description: "데이터 기반의 의사결정으로 비즈니스 성장을 가속화하세요."
          },
          {
            title: "크로스 플랫폼 지원",
            description: "PC와 모바일 앱에서 동일한 기능과 경험을 제공하여 언제 어디서나 업무가 가능합니다."
          },
          {
            title: "통합 관리 시스템",
            description: "디비관리, 고객관리, 실적관리, 업체관리에 특화된 기능으로 비즈니스 핵심 요소를 완벽하게 관리하세요."
          }
        ]
      },
      footer: {
        company: {
          name: "ORDARS",
          slogan: "비즈니스 성장을 위한 최고의 파트너"
        },
        contact: {
          title: "연락처",
          email: "이메일: ordars.com@gmail.com",
          tel: "전화: 02-1522-5512",
          address: "주소: 인천시 부평구 주부토로 236, B동 2114호"
        },
        social: {
          title: "팔로우",
          facebook: "페이스북",
          twitter: "트위터",
          instagram: "인스타그램",
          linkedin: "링크드인"
        },
        copyright: "© 2024 ordars.com. All rights reserved."
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language.startsWith('ko') ? 'ko' : 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;