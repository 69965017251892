import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  Stack,
  Link
} from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <Box
      id="home"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        color: 'white',
        pt: 8
      }}
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {t('hero.title')}
          </Typography>
          
          <Typography 
            variant="h5" 
            sx={{ 
              mb: 4,
              textAlign: 'center',
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            {t('hero.description')}
          </Typography>

          <Stack 
            direction="row" 
            spacing={2} 
            justifyContent="center"
            sx={{ mt: 4 }}
          >
            <Link 
              href="https://ordars.com" 
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              <Button 
                variant="contained" 
                size="large"
                sx={{ 
                  bgcolor: 'white', 
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: 'grey.100'
                  }
                }}
              >
                {t('hero.cta.start')}
              </Button>
            </Link>
            <ScrollLink
            to="features"
            smooth={true}
            duration={800}
            offset={-64}
            >
                <Button 
                variant="outlined" 
                size="large"
                sx={{ 
                    color: 'white',
                    borderColor: 'white',
                    '&:hover': {
                    borderColor: 'grey.100',
                    bgcolor: 'rgba(255,255,255,0.1)'
                    }
                }}
                >
              {t('hero.cta.learn')}
            </Button>
          </ScrollLink>
          </Stack>
        </motion.div>
      </Container>
    </Box>
  );
};

export default HeroSection;