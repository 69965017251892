import React from 'react';
import { 
  Box, 
  Container, 
  Grid, 
  Typography, 
  Paper 
} from '@mui/material';
import { 
  AllInclusive,
  TouchApp,
  TrendingUp,
  DevicesOutlined,
  StorageOutlined
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const BenefitItem = ({ icon, title, description, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      viewport={{ once: true }}
    >
      <Paper 
        elevation={0}
        sx={{ 
          p: 3, 
          height: '100%',
          textAlign: 'center',
          bgcolor: 'transparent',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-8px)'
          }
        }}
      >
        <Box sx={{ 
          mb: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80px'
        }}>
          {icon}
        </Box>
        <Typography 
          variant="h5" 
          gutterBottom
          sx={{ 
            fontWeight: '600',
            mb: 2
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ lineHeight: 1.7 }}
        >
          {description}
        </Typography>
      </Paper>
    </motion.div>
  );
};

const Benefits = () => {
  const { t } = useTranslation();

  const benefits = [
    {
      icon: <AllInclusive sx={{ fontSize: 60, color: 'primary.main' }} />,
      title: t('benefits.items.0.title'),
      description: t('benefits.items.0.description')
    },
    {
      icon: <TouchApp sx={{ fontSize: 60, color: 'primary.main' }} />,
      title: t('benefits.items.1.title'),
      description: t('benefits.items.1.description')
    },
    {
      icon: <TrendingUp sx={{ fontSize: 60, color: 'primary.main' }} />,
      title: t('benefits.items.2.title'),
      description: t('benefits.items.2.description')
    },
    {
      icon: <DevicesOutlined sx={{ fontSize: 60, color: 'primary.main' }} />,
      title: t('benefits.items.3.title'),
      description: t('benefits.items.3.description')
    },
    {
      icon: <StorageOutlined sx={{ fontSize: 60, color: 'primary.main' }} />,
      title: t('benefits.items.4.title'),
      description: t('benefits.items.4.description')
    }
  ];

  return (
    <Box 
      id="benefits"
      sx={{ 
        py: 8,
        bgcolor: '#f8f9fa',
        scrollMarginTop: '64px'
      }}
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <Typography 
            variant="h3" 
            component="h2" 
            textAlign="center" 
            mb={2}
            sx={{ fontWeight: 'bold' }}
          >
            {t('benefits.title')}
          </Typography>
          <Typography 
            variant="h6" 
            textAlign="center" 
            color="text.secondary"
            mb={6}
            sx={{ 
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: 1.6
            }}
          >
            {t('benefits.subtitle')}
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} md={index >= 3 ? 6 : 4} key={index}>
              <BenefitItem {...benefit} index={index} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Benefits;